import {sleep} from "../../assets/js/utils";

render();
function render() {
    const merkzettelLimitHinweis = document.querySelector('[data-template="merkzettel-limit-hinweis"]').innerHTML;
    document.body.insertAdjacentHTML('beforeend', merkzettelLimitHinweis);
};

class MerkzettelLimitHinweis extends HTMLElement {
    connectedCallback() {
        this.loginUrl = this.getAttribute('login-url');
        this.limitHinweisOverlay = this.querySelector('.limit-hinweis-overlay');
        this.querySelector('[interaction="limit-hinweis-schliessen"]').addEventListener('click', () => { this.limitOverlaySchliessen() });

        document.addEventListener('merkzettel-limit-hinweis', event => { this.limitHinweisAnzeigen(event) })
        document.addEventListener('click', event => this.documentKlickHandler(event));
        document.addEventListener("keydown", event => this.keydownHandler(event));
    }

    limitHinweisAnzeigen(event) {
        // erst unmittelbar vorm Anzeigen setzen, um Manipulationen an der URL mitzunehmen
        // (z. B. bei der After-Search-Navigation)
        this.initLoginUrls();
        this.limitHinweisOverlay.showModal();
    }

    initLoginUrls() {
        const loginUrl = this.loginUrl + "?source=" + encodeURIComponent(window.location.pathname + window.location.search);
        this.querySelector("[interaction=konto-anlegen]").setAttribute("href", loginUrl);
        this.querySelector("[interaction=anmelden]").setAttribute("href", loginUrl);
    }

    async limitOverlaySchliessen() {
        this.limitHinweisOverlay.setAttribute('closing', '');
        await sleep(500);
        this.limitHinweisOverlay.close();
        this.limitHinweisOverlay.removeAttribute('closing');
    }

    documentKlickHandler(event) {
        if (event.target === this.limitHinweisOverlay && this.overlayIstGeoeffnet) {
            this.limitOverlaySchliessen();
        }
    }

    keydownHandler(event) {
        if (this.overlayIstGeoeffnet && event.code === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.limitOverlaySchliessen();
        }
    }

    get overlayIstGeoeffnet() {
        return this.limitHinweisOverlay.hasAttribute('open');
    }
}

if (!customElements.get("merkzettel-limit-hinweis")) {
    customElements.define("merkzettel-limit-hinweis", MerkzettelLimitHinweis);
}
